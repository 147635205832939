<template>
    <v-app>
        <page-view />
    </v-app>
</template>

<script>
export default {
    name: 'LoginView',

    components: { PageView: () => import('@/components/page/PageView.vue')}
}
</script>
